import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
var Settings = (function (_super) {
    __extends(Settings, _super);
    function Settings() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Settings = __decorate([
        Component
    ], Settings);
    return Settings;
}(Vue));
export default Settings;
